import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/navabr/Navbar";
import Projects from "./components/Projects/Projects";
import {  BrowserRouter as Router, Route, Switch } from "react-dom";
import Home from "./components/home/Home.jsx";



function App() {
  return (
    <>
    {/* <Router> */}
      <Home/>
      {/* <Switch>
        <Route/>

        </Switch> */}

    {/* </Router> */}
    

    </>
      
      
    
  );
}

export default App;
