import React from 'react';

const Home = () => {
    return(
        <div className="hero-container">
        <h1> Work in progress</h1>
        <img src="https://media.tenor.com/images/183a000db7b523ec28547713a9fc7275/tenor.gif">
            
        </img>
        </div>

    );


  
   
};
export default Home;